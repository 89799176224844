<template>
  <div>
    <h3>Esta pagina no existe</h3>
  </div>
</template>
<script>
export default {
  name: "HomeView",
};
</script>
<style scoped>
div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  height: 90vh;
}
h3 {
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.3rem;
}
</style>
